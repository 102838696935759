import React, { Component } from 'react'
import { Col, Button, Row, Form, FormGroup, Input } from 'reactstrap';
import axios from 'axios';

export class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    async handleSubmit(e){
        e.preventDefault()

        console.log(this.state);

        const { firstName, lastName, email, message } = this.state;
        const form = await axios.post('localhost:3000/api/pvsform',{
            firstName,
            lastName,
            email,
            message
        });
        alert("Message sent");
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        });
    }

    render() {
        return (
            <div className="contactus-form" id="contactus-section">
                <div style={{margin:'2rem'}}>
                    <h1>Contact Us</h1>
                </div>
                <Form onSubmit={this.handleSubmit} style={{margin:'3rem'}}>
                    <Row form className="justify-content-center">
                        <Col md={3}>
                            <FormGroup>
                                <Input  type="text"
                                        name="firstName"
                                        id="fistName"
                                        placeholder="First Name"
                                        onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Input  type="text"
                                        name="lastName"
                                        id="lastName"
                                        placeholder="Last Name"
                                        onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row  form className="justify-content-center">
                        <Col md={6}>
                            <FormGroup>
                                <Input  type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form className="justify-content-center">
                        <Col md={6}>
                            <FormGroup>
                                <Input  type="textarea"
                                        name="message"
                                        id="message"
                                        placeholder="Type in your message"
                                        onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button>Send</Button>
                </Form>
            </div>
        )
    }
}

export default ContactUs
