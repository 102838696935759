import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import PVSLOGO from '../../images/PVSLogos/Logo3.png';
import './Footer.css';

export class Footer extends Component {
    render() {
        return (

            <div className="contactus-main" id="contactus-section">
                <Container style={{ height:'auto', padding:'4rem', backgroundColor:'black'}}>
                    <Row className='justify-content-center'>
                        <Col xs={8} sm={3} md={5} lg={3}>
                            <Image style={{height:'auto', width:'100%'}} src={PVSLOGO} thumbnail className="img-thumbnail mythumbnail" />
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col style={{ marginTop:'1rem', color:'white'}} md={12}>
                            © 2019 Pura Vida Software. All Rights Reserved.
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Footer
