import React, {Component, lazy, Suspense} from 'react';
import './App.css';
import NavigationBar from './components/NavigationBar/NavigationBar';
import AboutUs from './components/AboutUs/AboutUs';
//import Customers from './components/Customers/Customers';
//import ATeam from './components/ATeam/ATeam';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import MainPhrase from './components/MainPage/MainPhrase/MainPhrase';
//import MainBackGround from './components/MainPage/MainBackGround/MainBackGround';
//import AboutBackGround from './components/MainPage/AboutBackGround/AboutBackGround';
//import SecondaryBackground from './components/MainPage/SecondaryBackGround/SecondaryBackGround';
//import ThirdBackground from './components/MainPage/ThirdBackground/ThirdBackground';
const MainBackGround = lazy(() => import("./components/MainPage/MainBackGround/MainBackGround"));
const AboutBackGround = lazy(() => import("./components/MainPage/AboutBackGround/AboutBackGround"));
const SecondaryBackground = lazy(() => import("./components/MainPage/SecondaryBackGround/SecondaryBackGround"));
const ATeam = lazy(() => import("./components/ATeam/ATeam"));
const ThirdBackground = lazy(() => import("./components/MainPage/ThirdBackground/ThirdBackground"));
const Customers = lazy(() => import("./components/Customers/Customers"));

class App extends Component {
  render() {
    return (
      <div className="App">
        <NavigationBar />
        <Suspense fallback={<div>Loading...</div>}><MainBackGround /></Suspense>
        <MainPhrase />
        <Suspense fallback={<div>Loading...</div>}><AboutBackGround /></Suspense>
        <AboutUs />
        <MainPhrase />
        <Suspense fallback={<div>Loading...</div>}><SecondaryBackground /></Suspense>
        <Suspense fallback={<div>Loading...</div>}><ATeam /></Suspense>
        
        <MainPhrase />
        <Suspense fallback={<div>Loading...</div>}><ThirdBackground /></Suspense>
        <Suspense fallback={<div>Loading...</div>}><Customers /></Suspense>
        <MainPhrase />
        <ContactUs />
        <MainPhrase />
        <Footer />
      </div>
    );
  }
}

export default App;
