import React, { Component } from 'react';
import './AboutUs.css';
import { Container, Row, Col } from 'react-bootstrap';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export class AboutUs extends Component {
    render() {
        return (
            <div className="aboutus-main" id="aboutus-section">
                <Container className="container-fluid" style={{ height:'auto'}}>
                    <ScrollAnimation animateIn="fadeIn">
                        <Row>
                            <Col><h1>Who are we?</h1></Col>
                        </Row>
                        <Row style={{height:'3rem'}}><Col></Col></Row>
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                <h5>
                                    Pura Vida Software is a company based in Silicon Valley, inspired
                                    by the "pura vida" Costa Rican way of life.
                                    As a company we are highly committed to
                                    our clients and aim to give them the best services and experience. We provide the best suitable solutions to your problems
                                    in the shortest amount of time.
                                </h5>
                            </Col>
                        </Row>
                    </ScrollAnimation>

                    <Row style={{height:'3rem'}}><Col></Col></Row>
                    
                    <ScrollAnimation animateIn="fadeIn">
                        <Row className="justify-content-center">
                            <Col lg={12}><h1>Our Culture</h1></Col>
                        </Row>
                    </ScrollAnimation>
                    
                    <Row style={{height:'3rem'}}><Col></Col></Row>
                    <ScrollAnimation animateIn="flipInX">
                        <Row className=" justify-content-center ourculture-custom">
                            <Col md={2} lg={2}>
                                <i style={{marginTop:'2rem', marginBottom:'.5rem'}} className="fas fa-handshake fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >We show respect and integrity</p>
                            </Col>
                            <Col md={2} lg={2}>
                                <i style={{marginTop:'2rem', marginBottom:'.5rem'}} className="fab fa-leanpub fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >We are curious, striving for constant learning and improvement</p>
                            </Col>
                            <Col md={2} lg={2}>
                                <i style={{marginTop:'2rem', marginBottom:'.5rem'}} className="fab fa-connectdevelop fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >We keep the result in mind, with relentless Focus and Execution</p>
                            </Col>
                            <Col md={2} lg={2}>
                                <i style={{marginTop:'2rem', marginBottom:'.5rem'}} className="fas fa-hands-helping fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >We volunteer some of our time and resources to communities around us</p>
                            </Col>
                            <Col md={2} lg={2}>
                                <i style={{marginTop:'2rem', marginBottom:'3rem'}} className="far fa-comment fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >We value autonomy</p>
                            </Col>
                            <Col md={2} lg={2}>
                                <i style={{marginTop:'2rem', marginBottom:'.5rem'}} className="fas fa-user-tie fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >We are direct, transparent and seek to be honest, respectful and professional</p>
                            </Col>
                        </Row>
                    </ScrollAnimation>
                    <Row style={{height:'3rem'}}><Col></Col></Row>
                    <ScrollAnimation animateIn="fadeIn">
                        <Row>
                            <Col><h1>Our Services</h1></Col>
                        </Row>
                        <Row style={{height:'3rem'}}><Col></Col></Row>
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                
                            </Col>
                        </Row>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="flipInX">
                        <Row style={{padding:'4rem', marginBottom:'2rem'}} className=" justify-content-center ourservices-custom">
                            <Col md={4} lg={4}>
                            <i className="fas fa-laptop-code fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >Web applications: design and development</p>
                            </Col>
                            <Col md={4} lg={4}>
                                <i className="fas fa-mobile-alt fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >Phone applications: design and development</p>
                            </Col>
                            <Col md={4} lg={4}>
                                <i className="fab fa-aws fa-3x"></i>
                                <p style={{marginTop:'2rem'}} >Amazon Web Services</p>
                            </Col>
                        </Row>
                    </ScrollAnimation>

                </Container>
            </div>
        )
    }
}

export default AboutUs
