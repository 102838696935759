import React, { Component } from 'react';
import logo from '../../images/PVSLogos/officialogo.png';
import './NavigationBar.css';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };
    render() {
        return(
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" >
                        <Link
                        activeClass="active"
                        to="mainpage-section"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration= {500}>
                            <img
                            src={logo}
                            width="170"
                            height="50"
                            className="d-inline-block align-top"
                            alt="PVSLogo logo"/>
                        </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/">
                                    <Link
                                        activeClass="active"
                                        to="aboutus-section"
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration= {500}
                                        >About Us
                                    </Link>
                            </Nav.Link>
                            <Nav.Link href="/">
                                <Link
                                    activeClass="active"
                                    to="theteam-section"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration= {500}
                                    >The Team
                                </Link>
                            </Nav.Link>
                            <Nav.Link href="/">
                                <Link
                                    activeClass="active"
                                    to="customers-section"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration= {500}
                                    >Customers
                                </Link>
                            </Nav.Link>
                            <Nav.Link href="/">
                                <Link
                                    activeClass="active"
                                    to="contactus-section"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration= {500}
                                    >Contact Us
                                </Link>
                            </Nav.Link>
                        </Nav>
                        <Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}


export default NavigationBar;