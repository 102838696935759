import React, { Component } from 'react';
import './MainPhrase.css';
import { Container, Row } from 'react-bootstrap';

export class MainPhrase extends Component {
    render() {
        const insideStyles = {
            fontFamily: `'Oxygen', sans-serif`,
            fontSize: '3rem',
            color: 'white',
            padding: '2rem'
        };
        return (
            <div className="mainphrase-custom" >
                <Container style={{height:'1rem'}}>
                    <Row className="justify-content-center" style={insideStyles}>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default MainPhrase
